import Header from '../public/Header.jsx'
import Footer from '../public/Footer.jsx'

export default function Layout({ children }) {

  return (
    <>
      <Header />
        {children}
      <Footer />
    </>
  )
}
