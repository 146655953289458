const BaseUrl = process.env.NEXT_PUBLIC_API_URL;

//Google Map Endpoint
export const GoogleMapEndpoint = 'https://maps.googleapis.com/maps/api/geocode/json?';

//public endpoints
export const UserProfile = BaseUrl + 'user';
export const PopulerCategories = BaseUrl + 'poupler_categories';
export const PublicTechnology = BaseUrl + 'all_technologies';
export const AllCategories = BaseUrl + 'all_categories';
export const QuoteCategories = BaseUrl + 'quote/categories';
export const AllTags = BaseUrl + 'all_tags';

export const SubCategoryBySlug = BaseUrl + 'sub-category/'
export const CategorySlug = BaseUrl + 'category/'
export const Search = BaseUrl + 'global_search';
export const Tags = BaseUrl + 'tags';
export const GeneratePassword = BaseUrl + 'generate-new-pasword';

export const PublicBlogs = BaseUrl + 'home_blogs';
export const BlogsEndpoint = BaseUrl + 'all_blogs';
export const SingleBlogEndpoint = BaseUrl + 'blog/';
export const ContactTypes = BaseUrl + 'contact_us/types';
export const ContactUsEndpoint = BaseUrl + 'contact_us';
export const SearchReviewAgencies = BaseUrl + 'agencies/review';
export const AddReview = BaseUrl + 'add-review';
export const AgencyById = BaseUrl + 'search/agencies';
export const AgencyBySlug = BaseUrl + 'agency/';
export const getReview = BaseUrl + 'check-review/';
export const Subscriber = BaseUrl + 'newsletters';
export const GetUserProfile = BaseUrl + 'user';
export const SearchPortfolio = BaseUrl + 'global_search/portfolio/';
export const PortfolioListing = BaseUrl + 'global_search/portfolio';

export const Portfolio = BaseUrl + 'portfolio/';
export const AgencyPortfolioById = BaseUrl + 'business-portfolio/';
export const Attachment = BaseUrl + 'download/attachments/';
export const Outbox = BaseUrl + 'outbox';
export const InboxMessage = BaseUrl + 'inbox';
export const MessageUserList = BaseUrl + 'user-list/';
export const MessageSend = BaseUrl + 'compose';
export const BookmarkAdd = BaseUrl + 'users/add_bookmark';

//agency 

//quote endpoints
export const QuoteSearchAddress =  BaseUrl + 'search/address';
export const QuoteQuestion =  BaseUrl + 'first_question';
export const QuoteSendQuote =  BaseUrl + 'send_quote';
export const QuoteChildQuestion =  BaseUrl + 'child_question';
export const QuoteParentQuestion =  BaseUrl + 'parent_question';

// users routes
export const Activities = BaseUrl + 'activities';
export const Following = BaseUrl + 'following';
export const UserReviews = BaseUrl + 'users/all_reviews';
export const UserBookmarks = BaseUrl + 'users/bookmarks';
export const LikePortfolio = BaseUrl + 'liked-portfolio';
export const AgencyLikes = BaseUrl + 'agency/';
export const UserProfileEndpoint = BaseUrl + 'user-profile/';
export const UserUpdatePass = BaseUrl + 'update-password/';
export const ServiceRequest = BaseUrl + 'service-request';

// Agencies Routes
export const CheckPlan = BaseUrl + 'check-plan';
export const GetPackages = BaseUrl + 'get-pro-packages';
export const StripePayment = BaseUrl + 'stripe';
export const AgenciesForCards = BaseUrl + 'agencies/';
export const SubscriptionHistory = BaseUrl + 'payment-history';
export const LikedPortfolio = BaseUrl + 'liked-portfolio';
export const Followers = BaseUrl + 'followers';
export const AgencyPortfolio = BaseUrl + 'business-portfolio';
export const PortfolioStatus = BaseUrl + 'portfolio-status/';
// export const ServiceRequest = BaseUrl + 'service-request';



