import Link from 'next/link';
import {  List ,ListInlineItem} from 'reactstrap';
import { useState, useEffect } from "react";
import Image from 'next/image';
import {PublicTechnology} from '../../lib/Endpoints';
import Skeleton,  { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DefaultImage from '../../public/public/preview-image.jpg';

export default function Technologies(props) {
	const [data, setData] = useState([]);

    useEffect(() => {
    	let isPromise = props.populerTechnologies instanceof Promise;

    	if(isPromise) {
	    	props.populerTechnologies.then((res) => {
	    		setData(res);
	    	});
    	} else {
    		setData(props.populerTechnologies);
    	}

    }, [props.populerTechnologies]);

  	return (
	  	<>    
			<div className="cat_pop_listing">
				<List type="inline" key="5" className='services_home_listing'>
			    	{
						!data.length ? 
							<>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
										<div className="skeleton_lines">
										 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
												<Skeleton />
												<Skeleton />
											</SkeletonTheme>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
										<div className="skeleton_lines">
										 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
												<Skeleton />
												<Skeleton />
											</SkeletonTheme>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
										<div className="skeleton_lines">
										 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
												<Skeleton />
												<Skeleton />
											</SkeletonTheme>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
										<div className="skeleton_lines">
										 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
												<Skeleton />
												<Skeleton />
											</SkeletonTheme>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
										<div className="skeleton_lines">
										 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
												<Skeleton />
												<Skeleton />
											</SkeletonTheme>
										</div>
									</div>
								</ListInlineItem>
							</>			
					 	: ''
					}
					{
						data ? data.map((res, index) => {
							return (
								<ListInlineItem value={'item_'+index} key={index}>
									<a href={'/agencies/'+res.attributes.category_slug+'/'+res.attributes.slug + '/'} aria-label={res.attributes.name}>
										<div className="inner_wrap">         
										<div className="icon_block">
											<div className="inner-wrap">
											{res.attributes.image ? 
												<Image src={res.attributes.image} className="cat_img" layout='fill' alt="cover-img" width={50} height={50} unoptimized={true} /> : ''}
											</div>
										</div>
										<div className="cat_name_section service_name">
												<p>{res.attributes.name}</p>
										</div>
										</div>
									</a>
								</ListInlineItem>
						 	)
						}) : ''
					}
				</List>
			</div>
		</>
  	)
}
