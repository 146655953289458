
import Link from 'next/link';
import {  List ,ListInlineItem} from 'reactstrap';
import Image from 'next/image';
import {PopulerCategories} from '../../lib/Endpoints';
import Skeleton,  { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useState, useEffect } from "react";
import DefaultImage from '../../public/public/preview-image.jpg';

export default function HomeCategories(props) {
	const [data, setData] = useState([]);
	useEffect(() => {

		let isPromise = props.categories instanceof Promise;

    	if(isPromise) {
	    	props.categories.then((res) => {
	    		setData(res);
	    	});
    	} else {
    		setData(props.categories);
    	}
	}, [props.categories]);

  return (
	  <>
		<div className="cat_pop_listing">
			<List type="inline" key="5">
				{
					!data.length ? 
						<>
							<ListInlineItem>
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem>
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem>
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem>
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem>
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#ebebeb">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
						</>
					: ''
				}
				{
					data ? data.map((res, index) => {
						return (
							<ListInlineItem value={'item_'+index} key={index}>
								<a href={process.env.NEXT_PUBLIC_APP_URL + "/agencies/"+res.attributes.slug + '/'} aria-label={res.attributes.name}>
									<div className="inner_wrap">         
										<div className="img_section">
											<span>
												<Image src={res.attributes.image} className="cat-img" alt="cover img" layout='fill'/>
											</span>
											<div className="icon_block">
												<Image src={res.attributes.icon} alt="icon img" layout="fill"/>
											</div>
										</div>
										<div className="cat_name_section">
											<p>{res.attributes.name}</p>
										</div>
									</div>
								</a>
							</ListInlineItem>
						)
					}) : ''
				}
				</List>
			</div>
		</>
  	)
}
